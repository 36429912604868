@keyframes fadeInDown {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}
.header {
    width: 100%;
    z-index: 9999;
    transition: all ease 0.5s;
    position: absolute;
    top: 0;

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        animation-duration: 0.7s;
        animation-name: fadeInDown;
        animation-timing-function: ease-out;
    }

    .header-inner {
        background: #f1edea;
        .header-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo {
                display: block;
                cursor: pointer;
            }
        }
    }

    .nav_cover {
        display: flex;
        align-items: center;
    }

    .menu {
        display: none;
    }

    .nav {
        justify-content: end;
        gap: 10px;

        li {
            position: relative;

            &:active a {
                color: #1A76D1;
            }

            a {
                color: #2C2D3F;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
                padding: 25px 20px;
                display: inline-block;
                cursor: pointer;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    height: 3px;
                    width: 0%;
                    background: #1A76D1;
                    border-radius: 5px 5px 0 0;
                    opacity: 0;
                    transition: all 0.4s ease;
                }

                &:hover {
                    color: #1A76D1;

                    &::before {
                        opacity: 1;
                        width: 100%;
                        visibility: visible;
                    }
                }

            }
        }
    }

   
}
.active {
    background: #f5f5f7;
}


//========================== media query start here =================//
//--------tablet landscape--------//
@media screen and (max-width: 1200px) {
    .header {
        .header-inner {
            .header-nav {
                .logo {
                    width: 175px;
                }
            }
        }
    }
}

//--------tablet portrate---------//
@media screen and (max-width: 950px) {
    .header {
        .header-inner{
            .nav{
                gap: 1px;
                width: 100%;
               li{
                a{
                    padding: 14px 9px;
                            font-size: 12px;
                }
               }
            }
        }
        

    }

}
//--------mobile landscape---------//
@media screen and (max-width: 767px) {
    .header{
        .header-inner{
            .menu {
                display: block;
                position: absolute;
                right: 25px;
                font-size: 24px;
            }
            .nav_cover {
                height: 100vh;
                width: 100%;
                position: fixed;
                background: #fff;
                left: 0;
                top: 43px;
                flex-direction: column;
                padding-top: 2px;
                &.hide {
                    display: none;
                }
                .nav li{
                    width: 100%;
                    text-align: center;
                    a{
                        font-size: 14px;
                        background: #f1edea;
                        display: block;
                    }
                }
            }
            .login{
                width: 100%;
                position: fixed;
                bottom: 0px;
                left: 0;
                margin-left: 0;
                a{
                    width: 100%;
                }
            }
        }
       
    }
}




