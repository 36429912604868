.tabbing-cover {
position: relative;
    .tabs {
        display: flex;
        position: absolute;
        width: 100%;
        top: 0px;

        .tab {
            background: gray;
            flex: 1 1;
            padding: 10px 0;
            text-align: center;
            cursor: pointer;
            border: 1px solid;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border-radius: 5px;
        }

        .active {
            background: #f47a25;
            transition: all 0.5s ease;
        }

        .tabList {
            flex: 1 1;
            background: gray;
            text-align: center;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            padding: 10px 0;
            cursor: pointer;
            border: 1px solid;
            transition: all 0.5s ease;
            border-radius: 5px;

        }
    }

    .tab-data {
        padding: 40px 30px 0 30px;
        border-radius: 10px;
        background: rgba(232, 235, 237, 0.3);

        h2 {
            color: #f47a25;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            padding-top: 32px;
        }

        .tab-details {
            display: block;
            color: #f47a25;

            padding: 20px 0;
            strong {
                margin-bottom: 10px;
                display: block;
                color: #f47a25;
                    font-size: 14px;
                    font-weight: 800;
            }

            p {
                margin-bottom: 20px;
            }
        }

    }
}

// feactures  css-


//============================== media query start here ========================//
//--------tablet landscape--------//
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 1200px) {}

//--------tablet portrate---------//
@media screen and (max-width: 950px) {
    .tabbing-cover {
        .tab-data {
            h2 {
                font-size: 20px;
            }
        }
    }
}

//--------mobile landscape---------//
@media screen and (max-width: 767px) {
    .tabbing-cover {
        .tabs {
            .tab {
                font-size: 12px;
                padding: 3px ;
            }
            .tabList{
                font-size: 12px;
                padding: 3px ;
            }
        }
        .tab-data{
            h2{
                font-size: 16px;
                margin-bottom: 6px;
                padding-top: 20px;
            }
            .tab-details{
                strong{
                    margin-bottom: 1px;
                    
                }
                p{
                    margin-bottom: 5px;
                    text-align: justify;
                }
               
            }
        }
    }
}