.contacts {
	.breadcrumbs {
		background-image: url(../../assets//images/contact.jpg);
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		padding: 106px 0px;
		margin-bottom: 80px;

		&::before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: #a1cc3a;
			opacity: 0.8;
		}

		text-align: center;

		h2 {
			color: #fff;
			text-transform: capitalize;
			font-size: 38px;
			font-weight: 700;
			position: relative;
		}
	}

	.mapping-part {
		.inner {
			box-shadow: 0px 0px 10px #00000024;
			border-radius: 5px;
			margin-bottom: 60px;

			#map {
				height: 100%;
				width: 100%;
			}
			.connect {
				padding: 13px 40px;
				h2 {
					position: relative;
					font-size: 22px;
					color: #333;
					font-weight: 600;
					line-height: 27px;
					text-transform: capitalize;
					margin-bottom: 12px;
					padding-bottom: 20px;
					text-align: left;

					&::before {
						position: absolute;
						content: "";
						left: 0;
						bottom: 0;
						height: 2px;
						width: 50px;
						background: #1A76D1;
					}

				}
				p {
					font-size: 14px;
					color: #333;
					font-weight: 400;
					text-align: left;
					margin-bottom: 18px;
				}
				.support{
					padding: 15px 10px;
					background: rgba(232, 235, 237, 0.14);;
					border-radius:8px; 
					margin-bottom: 5px;
					text-align: center;
					h3 {
						font-size: 16px;
					}

					a {
						font-size: 15px;
						font-weight: 300;
						text-transform: none;
						color: #a1cc3a;
					}

					i {
						padding-right: 8px;
						display: inline-block;
						color: #a1cc3a;

					}
					div{
						margin-bottom: 5px;
					}
				}
				}
			}
		}
}

//============================== media query start here ========================//

//--------tablet landscape--------//
@media screen and (max-width: 1200px) {}

//--------tablet portrate---------//
@media screen and (max-width: 950px) {}

//--------mobile landscape---------//
@media screen and (max-width: 767px) {
	.contacts {
		.breadcrumbs {
			margin-bottom: 50px;
		}

	}

}