.footer {
	.footer-top {
		padding: 20px 0;
		position: relative;
		background: #777b74;

		.single-footer {
			padding: 0 17px;
			h2 {
				color: #fff;
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 15px;
				padding-bottom: 20px;
				text-transform: capitalize;
				position: relative;

				&::before {
					position: absolute;
					content: "";
					left: 0;
					bottom: 0px;
					height: 3px;
					width: 50px;
					background: #fff;
				}

			}
			h5{
				font-size: 15px;
				font-weight: 500;
				color: #fff;
			}

			p {
				color: #fff;
				font-size: 13px;
			}

			.social {
				margin-top: 10px;

				li {
					display: inline-block;
					margin-right: 10px;

					a {
						height: 34px;
						width: 34px;
						line-height: 34px;
						text-align: center;
						border: 1px solid #fff;
						text-align: center;
						padding: 0;
						border-radius: 100%;
						display: block;
						color: #fff;
						font-size: 16px;

						&:hover {
							color: #1A76D1;
							background: #fff;
							border-color: transparent;
						}
					}
				}
			}

			.newsletter-inner {
				margin-top: 20px;
				position: relative;

				input {
					background: transparent;
					border: 1px solid #fff;
					height: 44px;
					width: 100%;
					color: #fff;
					padding-left: 18px;
					padding-right: 70px;
					border-radius: 5px;
				}

				.button {
					position: absolute;
					right: 0;
					height: 44px;
					width: 50px;
					background: #fff;
					border-left: 1px solid #fff;
					text-shadow: none;
					box-shadow: none;
					display: inline-block;
					border: none;
					transition: all 0.4s ease;
					border-radius: 0 5px 5px 0;
					color: #777b74;
					font-size: 18px;

					&:hover i {
						color: #1A76D1;
					}

					i {
						transition: all 0.4s ease;
					}
				}

			}

			.footer-link {
				li {
					display: block;
					margin-bottom: 12px;

					a {
						display: block;
						color: #fff;
						text-transform: capitalize;
						transition: all 0.4s ease;
						font-weight: 400;

						&:hover {
							padding-left: 8px;
						}

						i {
							margin-right: 10px;
						}
					}
				}
			}
		}


	}

	.copyright {
		background: #696b68;
		padding: 16px 0;
		text-align: center;

		p {
			color: #fff;
					strong{
						color: #a1cc3a;
				}
			}

			a {
				color: #fff;
				font-weight: 400;
				text-decoration: underline;
				display: inline-block;
				margin-left: 4px;
			}
		}
	}

//============================== media query start here ========================//
//--------tablet landscape--------//
@media screen and (max-width: 1200px) {}

//--------tablet portrate---------//
@media screen and (max-width: 950px) {
	.footer .footer-top {
		padding: 35px 0 20px;
	}

	.footer .footer-top .single-footer .social li {
		margin-right: 5px;
	}

	.footer .footer-top .single-footer .newsletter-inner input {
		height: 39px;
	}

	.footer .footer-top .single-footer .newsletter-inner .button {
		height: 38px;
	}
}

//--------mobile landscape---------//
@media screen and (max-width: 767px) {
	.footer {
		.footer-top {
			padding: 25px 0 20px;
			.single-footer {
				margin-bottom: 27px;
				h2 {
					margin-bottom: 10px;
					padding-bottom: 10px;
				}
				.social{
					margin-top: 9px;
				}
				.footer-link{
					// display: flex;
					flex-direction: column;
					justify-content: space-around;
					li{
						margin-bottom: 0px;
					}
				}
				p{
					margin-bottom: 7px;
					line-height: 1.4;
				}

			}
		}
		

	}
}