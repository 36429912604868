//======================== product section =====================//
.product {
	.product-item {
		background: rgba(232, 235, 237, 0.14);
		border-radius: 14px;
		text-align: center;
		padding: 18px 0px;
		position: relative;
		overflow: hidden;
		margin-bottom: 16px;

		.readmore {
			color: #a1cc3a;
			font-size: 16px;
			font-weight: bold;
		}

		.animation {
			position: absolute;
			bottom: 0;
			left: 0;
			background: hsl(0, 0%, 99%);
			width: 100%;
			transform: translateY(100%);
			transition: all ease 0.5s;
		}

		&:hover .animation {
			transform: translateY(0%);
			padding: 10px 5px;
			border-radius: 10px;

		}

		h3,
		h6 {
			color: #f47a25;
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 7px;
			text-transform: uppercase;
		}

		h6 {
			font-size: 12px;
		}

		p {
			margin-bottom: 20px;
		}

		img {
			transition: all ease 0.5s;
			padding-bottom: 10px;
			width: 100%;

			&:hover {
				transform: scale(1.1);
			}
		}

		.meter-about {
			h6 {
				text-align: center;
				color: #f47a25;
				margin-bottom: 7px;
			}

			.meter-details {
				li {
					font-size: 13px;
					font-weight: 400;

					strong {
						color: #000;

						display: block;
						text-align: start;
					}
				}
			}
		}
	}


}

// ========================== mobile-app section ===============//
.mobile-app {
	padding-top: 20px;

	.single-mobile {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 11px;
		overflow: hidden;

		background: #fff;
		position: relative;
		transition: all 0.4s ease;
		display: flex;

		.mobile-head {
			min-width: 479px;

			img {
				transform: scale(1.1);
			}
		}

		.mobile-body {
			display: flex;
			align-items: center;
			padding: 0 15px;

			.mobile-content {
				margin-top: 10px;

				h3 {
					color: #2C2D3F;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
					margin-bottom: 10px;
				}

				p {
					font-size: 15px;
					margin-bottom: 7px;
				}

				.scanner {
					display: flex;
					align-items: center;
					gap: 20px;
					padding-bottom: 20px;

					img {
						width: 200px;
					}

					.store_icon {
						display: flex;
						gap: 21px;

						img {
							width: 61px;
						}
					}
				}
			}
		}
	}
}

//============================== media query start here ========================//
//--------tablet landscape--------//

@media screen and (max-width: 1200px) {
	.product {

		.meters {
			.product-item {
				&:hover {
					.animation {
						.meter-about {
							.meter-details {
								li {
									font-size: 10px;
								}
							}
						}
					}
				}
			}
		}

		.mobile-app {

			.single-mobile {
				.mobile-body {
					.mobile-content {
						margin-top: 32px;
					}
				}
			}
		}

	}

	//--------tablet portrate---------//

	@media screen and (max-width: 950px) {
		.product {
			.meters {
				.product-item {
					p {
						padding: 0px 77px;
					}

					img {
						width: 50%;
					}

					&:hover {
						.animation {
							.meter-about {
								.meter-details {
									li {
										padding: 0 100px;
									}
								}
							}
						}
					}
				}

			}

			.mobile-app {

				.single-mobile {
					flex-direction: column;

					.mobile-head {
						padding-bottom: 0px;

						img {
							transform: scale(1);
							margin-top: 0;
						}
					}

					.mobile-body {
						.mobile-content {
							margin-top: 0px;

							p {
								margin-bottom: 5px;
								font-size: 14px;
							}
						}
					}
				}
			}
		}



	}


	//--------mobile landscape---------//

	@media screen and (max-width: 767px) {
		.product {
			.meters {
				.product-item {
					p {
						padding: 0px 1px;
					}

					&:hover {
						.animation {
							.meter-about {
								.meter-details {
									li {
										font-size: 10px;
										padding: 0;
									}
								}
							}
						}
					}
				}
			}

			.mobile-app {

				.single-mobile {
					.mobile-head {
						min-width: 0px;
					}

					.mobile-body {
						.mobile-content {
							h3 {
								font-size: 13px;
							}

							p {
								font-size: 12px;
							}
						}
					}
				}
			}

		}

	}
}