.slider {
	padding-top: 50px;
	.owl-carousel {
		.owl-nav {
			margin: 0;
			position: absolute;
			top: 50%;
			width: 100%;
			margin-top: -25px;
			.owl-prev{
				left: 10px;
				&::after{
					content: '<';
					position: absolute;
					font-size: 24px;
					left: 0;
					right: 0;
					color: #fff;
					background: #1A76D1;
				}
			}
			.owl-next{
				right: 10px;
				&::after{
					content: '>';
					position: absolute;
					font-size: 24px;
					left: 0;
					right: 0;
					color: #fff;
					background: #1A76D1;
				}

			}
			div{
				height: 50px;
				width: 50px;
				line-height: 50px;
				text-align: center;
				background: #1A76D1;
				color: #fff;
				font-size: 26px;
				position: absolute;
				transition: all 0.4s ease;
				border-radius: 50%;
				font-size: 14px;
				&::hover{
					background: #2C2D3F;
					color: #fff;
				}
			}
		}
	}
}
//============================== media query start here ========================//

//--------tablet landscape--------//
@media screen and (max-width: 1200px) {
	.slider {
		.single-slice {
			.content {
				margin-left: 31px;
				h1 {
					font-size: 28px;
					line-height: 30px;
				}
				p {
					margin-top: 20px;
				}
			}
		}
	}
}
//--------tablet portrate---------//
@media screen and (max-width: 950px) {
	.slider{
		padding-top: 19px;
	}
}
//--------mobile landscape---------//
@media screen and (max-width: 767px) {
	.slider{
		.owl-carousel {
			.owl-nav {
			div{
				display: none;
			}
			}
		}
	}
	
}