@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/font-awesome/css/font-awesome.css';
@import '../node_modules/owl.carousel/dist/assets/owl.carousel.css';

/*======================================
CSS Table of contents-
* normalize CSS
* global CSS
* typography CSS
========================================*/


/*--------------------------------------
Normalize css-
--------------------------------------*/
html {
	text-size-adjust: 100%;
}

html,
body {
	height: 100%;
	margin: 0;
}

* {
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	overflow-x: hidden;
}

ul,
ul li {
	margin: 0;
	padding: 0;
	list-style: none;
}

a,
button {
	transition: all 0.3s ease;
	outline: none;
	box-shadow: none;
	text-decoration: none;
}

img {
	max-width: 100%;
}

figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
	display: block;
}
a {
	text-decoration: none;
}

a:active,
a:hover {
	outline: 0;
	text-decoration: none;
}

img {
	border: 0;
}

figure {
	margin: 1em 40px;
}

hr {
	box-sizing: content-box;
	height: 0;
}

pre {
	overflow: auto;
}
button {
	overflow: visible;
}

button {
	text-transform: none;
}

button,
html input[type="button"],
input[type="submit"] {
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::focus-inner,
input::focus-inner {
	border: 0;
	padding: 0;
}

input {
	line-height: normal;
}



/*--------------------------------------
Typography
--------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
	color: #424646;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	margin: 0;
}

p {
	color: #757575;
	margin: 0;
	line-height: 24px;
	font-weight: 400;
}

a,
button,
input {
	font-weight: 400;
}
em,
i {
	font-style: italic;
}


/*--------------------------------------
Global CSS 
--------------------------------------*/
body {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #888;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	color: #2C2D3F;
}

a,
button {
	transition: all 0.3s ease;
	outline: none;
	box-shadow: none;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

.btn {
	color: #fff;
	padding: 13px 25px;
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 500;
	background: #1A76D1;
	position: relative;
	box-shadow: none;
	display: inline-block;
	transition: all 0.4s ease;
	transform: perspective(1px) translateZ(0);
	border: none;
	border-radius: 0;
	border-radius: 4px;
}

.btn:hover {
	color: #fff;
}

.btn:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #2C2D3F;
	transform: scaleX(0);
	transform-origin: 50%;
	transition: all 0.4s ease;
	border-radius: 4px;
}

.btn:hover:before {
	transform: scaleX(1);
}
.section {
	padding: 82px 0 50px;
	border-bottom: 1px dotted;
}

.section-title {
	text-align: center;
	margin-bottom: 40px;
	padding: 0 140px;
}
.section-title h2 {
	font-size: 25px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 10px;
	position: relative;
	color: #2C2D3F;
}
.section-title p {
	font-size: 15px;
	color: #000;
	margin-top: 13px;
}




/*============================== media query start here ========================*/
/*--------tablet landscape--------*/
@media screen and (max-width: 1200px){
	.section-title {
		padding: 0 40px;
		margin-bottom: 40px;
	}
	.btn {
		color: #fff;
		padding: 5px 17px;
}
}

/*--------tablet portrate---------*/
@media screen and (max-width: 950px){
	.section-title{
			padding: 0 40px;
			margin-bottom: 40px;
			h2 {
				font-size: 26px;
		}
	} 
}
/*--------mobile landscape---------*/
@media screen and (max-width: 767px){
	html{
		overflow: hidden;
	}
	.section{
		padding: 51px 0;
	}
	.section-title{
		padding: 0 5px;
		h2 {
			font-size: 20px;
			margin-bottom: 12px;

	}
	p{
		font-size: 14px;
		text-align: justify;
	}
} 
}





