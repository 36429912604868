.sectors {
	.zoom {
		position: relative;
		text-align: center;
		h2 {
			margin-bottom: 7px;
			font-size: 16px;
			font-weight: bold;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		img {
			margin-bottom: 15px;
			height: 240px;
			width: 400px;
		}
		.zoom-content {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			color: #000;
			padding: 15px 0;
			margin: 5px;
			background: #fff;
			visibility: hidden;
		}
		&:hover .zoom-content {
			visibility: visible;
			transform: scale(1.1);
			text-align: center;
		}
	}
	h3 {
		padding-top: 0px;
		color: #2C2D3F;
		font-weight: 600;
		font-size: 15px;
		text-align: center;
		margin-bottom: 60px;
	}
}

//============================== media query start here ========================//

//--------tablet landscape---------//
@media screen and (max-width: 1200px) {
}
//--------tablet portrate---------//
@media screen and (max-width: 950px) {
	.sectors {
		h3 {
			display: none;
		}

		.zoom {
			text-align: start;

			p {
				margin-bottom: 0px;
			}

			img {
				height: 200px;
				width: 100%;
			}

			.zoom-content {
				position: static;
				border: none;
				visibility: visible;
				padding: 0;
			}

			&:hover .zoom-content {
				transform: scale(1);
				text-align: start;
			}

		}
	}
}
//--------mobile landscape---------//
@media screen and (max-width: 767px) {
	.sectors {
		.zoom {
			text-align: center;

			p {
				margin-bottom: 20px;
			}
			img {
				height: auto;
				width: 100%;
			}
			&:hover .zoom-content {
				text-align: center;
			}
		}

	}
}