.about {
	h3 {
		font-size: 24px;
		font-weight: 600;
		color: #2C2D3F;
		position: relative;
		padding:0 0 20px 0;
		margin-bottom: 24px;

		&::before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			height: 2px;
			width: 133px;
			background: #1a76d1;
		}
	}

	p {
		margin-bottom: 9px;
		text-align: justify;
	}

	.list li {
		color: #868686;
		margin-bottom: 12px;

		i {
			height: 15px;
			width: 15px;
			line-height: 15px;
			text-align: center;
			background: #1a76d1;
			color: #fff;
			font-size: 14px;
			border-radius: 100%;
			padding-left: 2px;
			margin-right: 16px;
		}
	}
	.about-right{
		padding-top: 25px;
	}
}
//============================== media query start here ========================//
//--------tablet landscape--------//
@media screen and (max-width: 1200px){
	.about{
		.about-right{
			img{
				padding-top: 50px;
			}
		}
	}
}
//--------tablet portrate---------//
@media screen and (max-width: 950px){
	.about{
		p{
			margin-bottom: 15px;

		}
		.about-right{
			padding-top: 0px;
			img{
				width: 100%;
				margin-bottom: 40px;
				padding-top: 0;
			 }
		} 
	}
	
 
}
//--------mobile landscape---------//
@media screen and (max-width: 767px){
	.about{
		p{
			margin-bottom: 7px;
		}
		.about-right{
			img{
				margin-bottom: 0px;
				padding-top: 8px;
			}
		}

}
}


