.wave-cover {
	.wave-architecture {
		margin-bottom: 45px;
		border: 1px solid;
		border-radius: 5px;
		background: #f1edea;
		padding: 10px;
	}

	.feacture-keys {
		padding-top: 15px;

		.feactures {
			position: relative;
			overflow: hidden;
			padding: 20px;
			display: flex;
			justify-content: center;

			.feacture-content {
				color: #fff;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				margin-bottom: 10px;
				opacity: 0;
				transition: all 0.4s ease;
				transform: translateX(400px);
			}

			&:hover img {
				visibility: hidden;
				transition: all 0.1s ease;
			}

			img {
				width: 120px;
				height: auto;
				transition: all 0.4s ease;
			}

			&:hover .feacture-content {
				opacity: 1;
				transition: all 0.4s;
				text-align: center;
				transform: translateX(0);
				transition: all 0.4s;
				border-radius: 5px;

				h2 {
					margin-bottom: 12px;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}

	}
}

//============================== media query start here ========================//
//--------tablet landscape--------//
@media screen and (max-width: 1200px) {
	.wave-cover {
		.feacture-keys {
			padding-top: 0;

			.feactures {
				img {
					width: 142px;
				}
			}
		}
	}
}

//--------tablet portrate---------//
@media screen and (max-width: 950px) {
	.wave-cover {
		.feacture-keys {
			.feactures {
				&:hover {
					.feacture-content {
						h2 {
							font-size: 14px;
						}
						p {
							font-size: 12px;
							line-height: 1.3;
						}
					}
				}

			}
		}
	}

	.wave-cover .wave-banner {
		height: 334px;
	}
}

//--------mobile landscape---------//
@media screen and (max-width: 767px) {
	.wave-cover {
		.feacture-keys {
			.feactures {
				padding: 15px 0;
				flex-direction: column;
				position: relative;
				text-align: center;

				img {
					margin: 0 auto;
				}

				.feacture-content {
					position: static;
					opacity: 0.9;
					transform: translateX(0px);

					p {
						text-align: center;
						font-size: 12px;
						line-height: 1.3;
					}
					h2 {
						font-size: 14px;
					}

				}

				&:hover {
					img {
						visibility: visible;
					}
				}
			}
		}
	}
}